import alloyAnalytics from '@telus/alloy-analytics'
import { TabsProps, ITabProps } from '../../blocks/core/Tabs/Tabs'
import { TabNavigationProps, TabProps } from '../../blocks/mobility/TabNavigation/TabNavigation'
import { ButtonGroupTabsProps, IButtonGroupTabsProps } from '../../blocks/mobility/ButtonGroupTabs/ButtonGroupTabs'

type SysID = TabsProps['sysID'] | TabNavigationProps['sysID'] | ButtonGroupTabsProps['sysID']
type EntryTitle = TabsProps['entryTitle'] | TabNavigationProps['entryTitle'] | ButtonGroupTabsProps['entryTitle']
type TabID = ITabProps['id'] | TabProps['id'] | IButtonGroupTabsProps['id']

type TabChangeProps = { sysID: SysID; entryTitle: EntryTitle; tabID: TabID }

const TOOL_TYPE = 'tab_navigation'

const alloyEvents = {
  tabChange: ({ sysID, entryTitle, tabID }: TabChangeProps) => {
    const toolSubmit = {
      name: `tab-${tabID}`,
      toolName: `tab_navigation-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: sysID,
      toolSelections: `tab-${tabID}`,
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
}

export default alloyEvents

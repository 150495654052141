interface IItems {
  id: string
}

/**
 * Function to find one object inside an array of objects containing id property
 *
 * @param items array of objects. Each object should have an id property
 * @param id object id to find
 * @returns matching object with id
 */
export const findItem = <T extends IItems>(items: T[], id: string) => items.find((item) => item.id === id)

/**
 * Function to find an array of objects inside an array containing the specified id
 *
 * @param items array of objects. Each object should have an id property
 * @param ids array of object ids to find
 * @returns array of matching objects with specified ids
 */
export function findItems<T extends IItems>(items: T[], ids: string[]) {
  return items.filter((item) => ids.includes(item.id))
}

/**
 * Function to find one object inside an array of objects containing id property
 *
 * @param items array of objects. Each object should have an id property
 * @param id object id to find
 * @returns index for matching object
 */
export const findItemIndex = <T extends IItems>(items: T[], id: string) => items.findIndex((i) => i.id === id)

import { ITabsFields } from 'contentful-types'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'

import Tabs from './Tabs'
import { A11yTextRenderer } from 'src/siteBuilder/utils/text/TextRenderer'

const ContentfulTabs = withContentful<ITabsFields>(Tabs, {
  sysID: (props) => props.sys.id,
  entryTitle: (props) => props.fields.entryTitle,
  tabs: (props) => {
    return props?.fields?.tabItems
      ?.filter((tab) => tab.fields)
      .map((tab) => {
        const id = tab?.fields?.id?.split(' ').join('-')
        return {
          id: id,
          label: <A11yTextRenderer>{tab.fields.label}</A11yTextRenderer>,
          href: `#${id}`,
          content: <BlockRenderer block={tab.fields.content} />,
        }
      })
  },
})

export default ContentfulTabs

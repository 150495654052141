import { useEffect, useContext, useState } from 'react'
import { FlexGrid, Tabs as AlliumTabs } from '@telus-uds/components-web'

import { findItem, findItemIndex } from '../../../utils/findItem'
import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'
import TabsContainer from './styles'
import useKoodoAnalyticsEvent from '../../../../hooks/useKoodoAnalyticsEvent'
import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'
import { ContentfulAnalyticsEventType } from '@/siteBuilder/utils/types/analytics'
import WithEarlyReturn from '../../../../utils/WithEarlyReturn'
import alloyEvents from '../../../utils/alloyAnalytics/tabs'

export interface ITabProps {
  id: string
  label: string | JSX.Element
  href: string
  content: JSX.Element
}

export interface TabsProps {
  sysID: string
  entryTitle: string
  tabs: ITabProps[]
  inverse?: boolean
  linkAnalyticsEventsArray?: ContentfulAnalyticsEventType[]
  eqHeightTabs?: boolean
  hasScroll?: boolean
  scrollToTabs?: boolean
  dynamicWidth?: boolean
}

const Tabs = ({
  sysID,
  entryTitle,
  tabs,
  inverse = false,
  linkAnalyticsEventsArray = undefined,
  eqHeightTabs = false,
  hasScroll = true,
  scrollToTabs = true,
  dynamicWidth = false,
  ...rest
}: TabsProps) => {
  const tabId = tabs?.length ? tabs.reduce((acc, curr) => acc + curr.id, '') : null

  const { setActiveTabIds } = useContext(SiteBuilderContext)
  const [selectedId, setSelectedId] = useState<string>(null)
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const selectedAnalyticsEvent = linkAnalyticsEventsArray ? linkAnalyticsEventsArray[selectedTabIndex] : null

  useKoodoAnalyticsEvent(selectedAnalyticsEvent, isAnalyticsTriggered)

  useEffect(() => {
    if (!selectedId) {
      if (window.location.hash) {
        const selectedId = findItem(tabs, window.location.hash.replace('#', ''))?.id
        if (selectedId) return setSelectedId(selectedId)
      }
      setSelectedId(tabs[0]?.id)
    }
  }, [])

  useEffect(() => {
    setActiveTabIds(tabId, selectedId)
  }, [selectedId])

  if (!tabs || tabs.length === 0) return null

  const renderedTabs = tabs?.map((tab) => (
    <div id={hasScroll && tab.id} key={tab.id} className={selectedId === tab.id ? 'active' : 'inactive'}>
      {tab.content}
    </div>
  ))

  const handlePress = (tab: ITabProps, event) => {
    alloyEvents.tabChange({ sysID, entryTitle, tabID: tab.id })

    const isInternalLink = tab.href && tab.href.startsWith('#')

    if (!scrollToTabs && isInternalLink) {
      // Prevent the default behaviour of automatic scrolling to Tabs component upon clicking a tab
      event.preventDefault()
      // Manually update the URL without scrolling
      window.history.pushState({}, '', tab.href)
    }
  }

  const getItemTokens = (dynamicWidth: boolean) => {
    return dynamicWidth ? { maxWidth: 'none' } : {}
  }

  return (
    <TabsContainer data-testid="allium-tabs-testid" equalHeightTabs={eqHeightTabs}>
      {hasScroll &&
        // This is to allow to scroll to the desired tab
        tabs?.map((tab) => <div key={tab.id} id={tab.id} />)}
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col>
            <AlliumTabs
              onChange={(id: string) => {
                setSelectedId(id)
                setSelectedTabIndex(findItemIndex(tabs, id))
                setIsAnalyticsTriggered(true)
              }}
              value={selectedId}
              items={tabs.map((tab) => ({
                ...tab,
                onPress: (event) => handlePress(tab, event),
              }))}
              variant={{ inverse }}
              itemTokens={getItemTokens(dynamicWidth)}
              dataSet={mapDataAttrsToDataSet(rest)}
            />
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
      <div className="tab-content" data-testid="tabs-content">
        {renderedTabs}
      </div>
    </TabsContainer>
  )
}

const isValid = (tabs: ITabProps[]): boolean => {
  if (!tabs || tabs.length === 0) {
    return false
  }
  return true
}

export default WithEarlyReturn(Tabs, 'tabs', isValid, 'No tabs to render')

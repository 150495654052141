import styled, { css } from 'styled-components'

type ContainerProps = {
  equalHeightTabs: boolean
}

const TabsContainer = styled.div<ContainerProps>`
  .tab-content > div {
    visibility: hidden;
  }
  .tab-content > .active {
    visibility: visible;
  }
  .tab-content > .inactive {
    display: ${(props) => !props.equalHeightTabs && 'none'};
  }
  ${({ equalHeightTabs }) =>
    equalHeightTabs &&
    css`
      .tab-content {
        display: flex;
      }
      .tab-content > div {
        visibility: hidden;
        display: block;
        margin-right: -100%;
        width: 100%;
      }
    `};
`

export default TabsContainer
